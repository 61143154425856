import React from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { Box, Text, useTheme } from '@chakra-ui/core';
import { GoPrimitiveDot } from 'react-icons/go';
import { FaArrowRight } from 'react-icons/fa';
import APPARTS from '../../static-data/appartements';
import { HouseCard } from '../common';

const AppartsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 40px;
`;

const ItemWrapper = styled.div``;

const AvaibilityStatus = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin-bottom: 16px;
  margin-top: -16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const GoToButton = styled.button`
  background: ${props => props.colors.blue[700]};
  color: ${props => props.colors.gray[200]};
  padding: 12px;
  width: 100%;
  margin: 24px 56px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 200ms;

  &:hover {
    background: ${props => props.colors.blue[800]};
  }
`;

const getAvailabilityDetails = (item, availabilities) => {
  const itemAvailability = availabilities.filter(
    a => a.property_id === item.appartID
  );

  const isAvailable =
    itemAvailability[0].periods.length > 1 ||
    itemAvailability[0].periods[0].available === 0
      ? false
      : true;

  if (isAvailable) {
    return {
      color: 'green.400',
      text: 'Disponible :)',
      available: true,
    };
  }

  return {
    color: 'red.500',
    text: 'Indisponible :/',
    available: false,
  };
};

const HouseGrid = ({ properties }) => {
  const theme = useTheme();

  const onClick = slug => {
    navigate(`/${slug}`);
  };

  return (
    <AppartsWrapper>
      {APPARTS.sort(item =>
        getAvailabilityDetails(item, properties).available ? -1 : 1
      ).map((item, index) => {
        const details = getAvailabilityDetails(item, properties);
        return (
          <ItemWrapper key={index}>
            <AvaibilityStatus>
              <Box as={GoPrimitiveDot} color={details.color} size="32px" />
              <Box as="b" ml="2" color="gray.600" fontSize="xl">
                {details.text}
              </Box>
            </AvaibilityStatus>
            <HouseCard property={item} key={index} />
            <ButtonWrapper>
              <GoToButton
                colors={theme.colors}
                onClick={() => onClick(item.slug)}
              >
                <Box as={FaArrowRight} marginRight="8px" marginTop="2px" />
                <Text fontSize="lg">Voir l'appartement</Text>{' '}
              </GoToButton>
            </ButtonWrapper>
          </ItemWrapper>
        );
      })}
    </AppartsWrapper>
  );
};

export default HouseGrid;
