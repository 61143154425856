import React from 'react';
import { navigate } from 'gatsby';
import { Heading, Text, Spinner } from '@chakra-ui/core';
import moment from 'moment';
import styled from 'styled-components';
import Layout from '../components/skeleton/layout';
import SEO from '../components/skeleton/seo';
import Banner from '../components/booking/Banner';
import HouseGrid from '../components/booking/house-grid';

const MainContent = styled.div`
  margin-top: 40px;
  min-height: 85vh;
  width: 100%;
`;

const LoaderWrapper = styled.div`
  width: 100%;
  min-height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function catchError(error) {
  console.error(error);
}

const BookingPage = ({ location }) => {
  const [availableProperties, setAvailableProperties] = React.useState([]);
  const [dates, setDates] = React.useState({ start: null, end: null });

  React.useEffect(() => {
    if (location.state === null) {
      navigate('/');
      return;
    }

    const { startDate, endDate } = location.state;
    const start = moment(startDate).format('YYYY/MM/DD');
    const end = moment(endDate).format('YYYY/MM/DD');

    setDates({ start, end });

    fetch(`/.netlify/functions/node-fetch?start=${start}&end=${end}`, {
      headers: { accept: 'Accept: application/json' },
    })
      .then(response => response.json())
      .then(resultData => {
        setAvailableProperties(resultData);
      })
      .catch(catchError);
  }, [location.state]);

  if (location.state === null) {
    return null;
  }

  const displayedDate = `${moment(dates.start).format(
    'DD MMMM YYYY'
  )} au ${moment(dates.end).format('DD MMMM YYYY')}`;

  return (
    <Layout headerChildren={<Banner />}>
      <SEO title="Disponibilité de nos appartements" />

      <MainContent>
        <Heading textAlign="left" size="xl" color="gray.600">
          Disponibilité du {displayedDate}
        </Heading>

        {availableProperties.length ? (
          <HouseGrid properties={availableProperties} />
        ) : (
          <LoaderWrapper>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </LoaderWrapper>
        )}
      </MainContent>
    </Layout>
  );
};

export default BookingPage;
