import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Box, useTheme } from '@chakra-ui/core';
import BackgroundImage from 'gatsby-background-image';

const Banner = () => {
  const theme = useTheme();
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "header.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const backgroundFluidImageStack = [
    `linear-gradient(rgba(0, 0, 0, 0.43), rgba(0, 0, 0, 0.43))`,
    data.placeholderImage.childImageSharp.fluid,
  ];

  return (
    <BackgroundImage
      Tag="section"
      fluid={backgroundFluidImageStack}
      backgroundColor={theme.colors.gray[500]}
      backgroundPosition="bottom"
      alt="Photo de la facade d'un de nos appartements"
    >
      <Box
        spacing={16}
        align="center"
        justify="start"
        w="100%"
        maxW="960px"
        h="50vh"
        m="0 auto"
        pt="36px"
      />
    </BackgroundImage>
  );
};

export default Banner;
